<script setup lang="ts">
const props = defineProps<{
  videoUrl: string;
}>();

const isExpanded = ref(false);

const videoPlayer = ref<HTMLVideoElement>();

const { sendScreenView } = useAnalytics();

const { getCdnUrl } = useEnvPaths();

function getVideoName() {
  return props.videoUrl.split("/").pop();
}

watch(videoPlayer, () => {
  initVideoPlayer();
});

onMounted(() => {
  initVideoPlayer();
});

function initVideoPlayer() {
  if (videoPlayer.value) {
    videoPlayer.value.addEventListener("playing", onVideoPlaying);
    videoPlayer.value.addEventListener("ended", onVideoEnded);
  }
}

function onPlayVideo() {
  isExpanded.value = true;
}

onUnmounted(() => {
  onVideoUnmounted();
});

function onCloseVideo() {
  onVideoUnmounted();
  isExpanded.value = false;
}

function onVideoPlaying() {
  sendScreenView({
    type: "Video.play",
    target: `${getVideoName()}`,
  });
}

function onVideoEnded() {
  sendScreenView({
    type: "Video.ended",
    target: `${getVideoName()}`,
  });
  isExpanded.value = false;
}

function onVideoUnmounted() {
  if (videoPlayer.value) {
    videoPlayer.value.removeEventListener("playing", onVideoPlaying);
    videoPlayer.value.removeEventListener("ended", onVideoEnded);
  }
}
</script>

<template>
  <div class="video-player-container">
    <div v-if="!isExpanded" class="video-placeholder" @click="onPlayVideo">
      <div class="play"></div>
      <slot />
    </div>
  </div>

  <Teleport to="body">
    <Transition name="fade">
      <div v-if="isExpanded" class="video-player-expanded">
        <div class="video-player">
          <div class="close-button">
            <button @click="onCloseVideo" />
          </div>
          <video ref="videoPlayer" :src="`${getCdnUrl()}${props.videoUrl}`" controls autoplay />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss">
.video-player-container {
  .video-placeholder {
    margin: auto;
    max-width: 350px;
    position: relative;
    width: 100%;

    img {
      width: 100% !important;
    }

    .play {
      background: transparent;
      border: 0;

      border-color: transparent transparent transparent #fff;

      border-style: solid;
      border-width: 37px 0 37px 60px;
      box-sizing: border-box;
      cursor: pointer;
      height: 74px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-25%) translateY(-50%);
      transition: 100ms all ease;
      width: 0;
    }
  }
}

.video-player-expanded {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;

  .video-player {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: auto;
    width: 70%;

    .close-button {
      text-align: right;

      button {
        background: transparent;
        border: none;
        color: white;
        cursor: pointer;
        font-size: 2rem;

        &:before {
          content: "\2715";
        }
      }
    }

    video {
      height: 100%;
    }
  }
}
</style>
